import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import linkResolver from "../utils/linkResolver"

function IndexPage(props) {
  const apps = props.data.allPrismicApp.edges.slice(0, 3).map(e => e.node);

  return (
    <Layout graphic={true}>
      <SEO title="Home" keywords={[`kickcode`, `apps`, `games`, `ruby`, `react`, `unity`]} />
      <div className="apps">
        {apps.map((node, i) => (
          <div className="app pure-u-xl-1-3 pure-u-md-1-2 pure-u-1-1" key={i}>
            <h2 className="title uppercut">{node.data.name.text}</h2>
            <p className="description">
              {node.data.summary.text}
            </p>
            {node.data.logo?.thumbnails?.thumb?.fixed && (<Img fixed={node.data.logo.thumbnails.thumb.fixed} />)}

            <br />
            <Link to={linkResolver(node)} className="button uppercut">See more</Link>
          </div>
        ))}
        <div className="clear" />
      </div>
    </Layout>
  );
};

export default IndexPage

export const pageQuery = graphql`
  query {
    allPrismicApp(sort: {fields: data___date, order: DESC}) {
      edges {
        node {
          id
          type
          uid
          data {
            name {
              text
            }
            summary {
              text
            }
            tags {
              tag
            }
            logo {
              thumbnails {
                thumb {
                  fixed(width: 150) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`